import React, { useState, useEffect } from 'react';
import { db } from "./firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import { useLocation } from "react-router-dom";
import Router from './Router';
import './Reset.css';
import styles from './App.module.css';

function App() {
  const [pages, setPages] = useState();
  const location = useLocation()

  const fetchPages = async () => {
    if (pages) {
      return;
    }
    try {
      const q = query(collection(db, "weddings", "m-n-mwed", "website-pages"), where("published", "==", true));
      const doc = await getDocs(q);

      let nextPages = [];
      doc.docs.forEach(async (doc) => {
        nextPages.push({
          docId: doc.id,
          ...doc.data(),
        });
      });

      setPages(nextPages);
    } catch (err) {
      console.error(err);
      alert("An error occurred while fetching website pages");
    }
  };

  useEffect(() => {
    fetchPages();
  }, []);

  return (
    <>
      <div className={styles.App}>
        <Router pages={pages} />
        <footer className={styles.Footer}>
          <div className={styles.Header1}>M&amp;M</div>
          <div className={styles.Header3}>25.02.2023</div>
        </footer>
      </div>
    </>
  );
}

export default App;
