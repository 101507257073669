import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { db } from "./firebase";
import { query, doc, getDoc, updateDoc, where, Timestamp } from "firebase/firestore";
import { slugify } from "./utils";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import styles from "./ContentPageForm.module.css";
import { atcb_action, atcb_init } from 'add-to-calendar-button';
import 'add-to-calendar-button/assets/css/atcb.css';

let CALENDAR_EVENT_DESCRIPTION = `3:45PM: CEREMONY
The Macarthur Rose Garden
King Edward Terrace, Parkes, ACT, Australia, 2600

Please join Mike from 3: 45pm for a drink before the ceremony at the Kiosk in the centre of the Old Parliament House Rose Gardens.

The ceremony will start at 4:30pm sharp with drinks and photos at the Kiosk to follow.

---

6:30PM: RECEPTION
The Duxton - Upstairs at The Loft
Corner of Sargood and Macpherson Streets, O'Connor, ACT, Australia, 2602

Join us at 6:30pm sharp for the reception at The Loft, upstairs at The Duxton in O'Connor. Food and drinks will be provided.`;

const CALENDAR_EVENT = {
  name: "Mike and Meredith's Wedding",
  startDate: "2023-02-25",
  endDate: "2023-02-25",
  startTime: "15:45",
  endTime: "23:30",
  description: CALENDAR_EVENT_DESCRIPTION,
  location: "The Macarthur Rose Garden, King Edward Terrace, Parkes ACT 2600, Australia",
  options: ['Apple', 'Google', 'iCal', 'Microsoft365', 'Outlook.com', 'Yahoo'],
  timeZone: "Australia/Sydney",
  iCalFileName: "Reminder-Event",
}

class UnansweredQuestion {
  unanswered = true;
}

const QuestionGuestYesNo = ({ question, household, guests, onChangeGuestAnswer, onChangeHouseholdAnswer }) => {
  return (
    <ul className={styles.GuestAnswers}>
      {household?.guests.map((guest) => {
        return (
          <li className={styles.GuestAnswer}>
            <div className={styles.GuestName}>
              {guest.firstName}
            </div>
            <div className={styles.GuestYesNoContainer}>
              <span className={styles.YesNoToggle}>
                <input
                  id={`${question.id}-${guest.id}-yes`}
                  name={`${question.id}-${guest.id}`}
                  checked={question.guestAnswers[guest.id] === true}
                  type="radio"
                  onChange={(e) => onChangeGuestAnswer(guest.id, true)}
                />
                <label
                  for={`${question.id}-${guest.id}-yes`}
                >
                  Yes
                </label>
              </span>
              <span className={styles.YesNoToggle}>
                <input
                  id={`${question.id}-${guest.id}-no`}
                  name={`${question.id}-${guest.id}`}
                  checked={question.guestAnswers[guest.id] === false}
                  type="radio"
                  onChange={(e) => onChangeGuestAnswer(guest.id, false)}
                />
                <label
                  for={`${question.id}-${guest.id}-no`}
                >
                  No
                </label>
              </span>
            </div>
          </li>
        );
      })}
    </ul>
  )
}

const QuestionGuestYesNoQualified = ({ question, household, guests, onChangeGuestAnswer, onChangeHouseholdAnswer }) => {
  return (
    <ul className={styles.GuestAnswers}>
      {household?.guests.map((guest) => {
        if (question.guestAnswers[guest.id]?.unanswered || question.guestAnswers[guest.id] === null) {
          return (
            <li className={styles.GuestAnswer}>
              <div className={styles.GuestName}>
                {guest.firstName}
              </div>
              <div className={styles.GuestYesNoContainer}>
                <span className={styles.YesNoToggle}>
                  <input
                    id={`${question.id}-${guest.id}-yes`}
                    name={`${question.id}-${guest.id}`}
                    checked={question.guestAnswers[guest.id] === true}
                    type="radio"
                    onChange={(e) => onChangeGuestAnswer(guest.id, "")}
                  />
                  <label
                    for={`${question.id}-${guest.id}-yes`}
                  >
                    Yes
                  </label>
                </span>
                <span className={styles.YesNoToggle}>
                  <input
                    id={`${question.id}-${guest.id}-no`}
                    name={`${question.id}-${guest.id}`}
                    checked={question.guestAnswers[guest.id] === null}
                    type="radio"
                    onChange={(e) => onChangeGuestAnswer(guest.id, null)}
                  />
                  <label
                    for={`${question.id}-${guest.id}-no`}
                  >
                    No
                  </label>
                </span>
              </div>
            </li>
          );
        }
        if (typeof question.guestAnswers[guest.id] === 'string' || question.guestAnswers[guest.id] instanceof String) {
          return (
            <li className={styles.GuestAnswer}>
              <div className={styles.GuestName}>
                {guest.firstName}
              </div>
              <div className={styles.GuestFreeTextContainer}>
                <span className={styles.GuestFreeTextQualifiedHelp}>
                  Please provide details or <a role="button"
                    className={styles.GuestFreeTextQualifiedHelpButton}
                    onClick={(e) => onChangeGuestAnswer(guest.id, new UnansweredQuestion())}
                  >
                    Change your answer
                  </a>.
                </span>
                <textarea
                  rows="4"
                  className={styles.FreeTextQualified}
                  value={question.answer}
                  onChange={(e) => onChangeGuestAnswer(guest.id, e.target.value)} />
              </div>
            </li>
          );
        }
      })}
    </ul>
  )
}

const QuestionHouseholdFreeText = ({ question, household, guests, onChangeGuestAnswer, onChangeHouseholdAnswer }) => {
  return (
    <>
      <textarea
        rows="4"
        className={styles.FreeText}
        value={question.answer}
        onChange={(e) => onChangeHouseholdAnswer(e.target.value)} />
    </>
  )
}

const QuestionGuestFreeText = ({ question, household, guests, onChangeGuestAnswer, onChangeHouseholdAnswer }) => {
  return (
    <ul className={styles.GuestAnswers}>
      {household?.guests.map((guest) => {
        return (
          <li className={styles.GuestAnswer}>
            <div className={styles.GuestName}>
              {guest.firstName}
            </div>
            <div className={styles.GuestFreeTextContainer}>
              <textarea
                rows="4"
                className={styles.FreeText}
                value={question.answer}
                onChange={(e) => onChangeGuestAnswer(guest.id, e.target.value)} />
            </div>
          </li>
        );
      })}
    </ul>
  )
}

const getInitialFormData = (household, form) => {
  const getInitialGuestAnswers = (initialAnswer) => {
    const guestAnswers = {};
    household?.guests.forEach((guest) => {
      guestAnswers[guest.id] = initialAnswer;
    });
    return guestAnswers;
  }

  const formData = [];
  form?.questions.forEach((question) => {
    const questionData = {
      ...question,
      ref: React.createRef(),
    };

    if (question.cardinality === 'GUEST' && question.type === 'YES_NO_QUALIFIED') {
      questionData.guestAnswers = getInitialGuestAnswers(new UnansweredQuestion());
    } else if (question.cardinality === "GUEST") {
      questionData.guestAnswers = getInitialGuestAnswers(null);
    } else if (question.cardinality === "HOUSEHOLD") {
      questionData.answer = "";
    }

    formData.push(questionData);
  })

  return formData;
}

export default function ContentPageForm({ household, formId, isComplete, onFormSubmit }) {
  let params = useParams();
  const [guests, setGuests] = useState();
  const [form, setForm] = useState();
  const [formData, setFormData] = useState();
  const [currentQuestion, setCurrentQuestion] = useState(0);

  const fetchForm = async () => {
    if (!formId) {
      return;
    }

    try {
      const formDoc = await getDoc(doc(db, "weddings", "m-n-mwed", "forms", formId));
      const data = formDoc.data();

      setForm(data);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching form data");
    }
  }

  useEffect(() => {
    fetchForm();
  }, [formId]);

  useEffect(() => {
    setFormData(getInitialFormData(household, form));
  }, [form, household]);

  const setGuestAnswer = (questionId, guestId, answer) => {
    const nextFormData = [];
    formData.forEach((question) => {
      if (question.id === questionId) {
        nextFormData.push({
          ...question,
          guestAnswers: {
            ...question.guestAnswers,
            [guestId]: answer,
          }
        });
      } else {
        nextFormData.push(question);
      }
    });

    setFormData(nextFormData);
  }

  const setHouseholdAnswer = (questionId, answer) => {
    const nextFormData = [];
    formData.forEach((question) => {
      if (question.id === questionId) {
        nextFormData.push({
          ...question,
          answer: answer,
        });
      } else {
        nextFormData.push(question);
      }
    });

    setFormData(nextFormData);
  }

  const handleOnSubmit = async () => {
    setCurrentQuestion(currentQuestion + 1);

    const newResponses = [];
    const filteredFormData = formData?.filter((question) => isQuestionIncluded(question));
    filteredFormData.forEach((data) => {
      const response = {
        formId: formId,
        questionId: data.id,
      }
      if (data.guestAnswers) response.guestAnswers = data.guestAnswers;
      if (data.answer) response.answer = data.answer;

      newResponses.push(response);
    });

    let existingResponses = [];
    let household;
    try {
      const householdDoc = await getDoc(doc(db, "weddings", "m-n-mwed", "households", params.householdId));
      household = householdDoc.data();
      if (household.formResponses) existingResponses = household.formResponses;
    } catch (err) {
      console.error(err);
      alert("An error occurred while fetching household data");
    }

    const responseDateTime = Timestamp.fromDate(new Date());

    let nextResponses = [];
    // For each of the existing responses
    existingResponses.forEach((existingResponse) => {
      // see if we can find a corresponding new response for the same form and question
      const newResponseIdx = newResponses.findIndex((newResponse) => newResponse.formId === existingResponse.formId && newResponse.questionId === existingResponse.questionId);
      // we found an new response so we must be updating and therefore favour the new response
      if (newResponseIdx >= 0) {
        const newResponse = newResponses[newResponseIdx];
        // add the new response (rather than the existing) to the next array
        nextResponses.push({
          ...newResponse,
          responseDateTime: responseDateTime,
        });
        // remove the new response from the list of new responses
        newResponses.splice(newResponseIdx, 1);
      } else {
        // we didn't find a new response so make sure we keep the existing one
        nextResponses.push(existingResponse);
      }
    });

    // We've remove any responses that we've already processed as we've gone. So we can simply
    // push anything that's remaining (and add in the timestamp).
    newResponses.forEach((response) => {
      nextResponses.push({
        ...response,
        responseDateTime: responseDateTime,
      })
    })

    await updateDoc(doc(db, "weddings", "m-n-mwed", "households", params.householdId), {
      formResponses: nextResponses,
    });
  }

  const isQuestionValid = (question) => {
    if (!question.required) return true;

    switch (true) {
      case (question.type === "YES_NO" && question.cardinality === "GUEST"):
        for (let guest of Object.keys(question.guestAnswers)) {
          if (question.guestAnswers[guest] !== true && question.guestAnswers[guest] !== false) {
            return false;
          }
        }
        return true;
      case (question.type === "YES_NO_QUALIFIED" && question.cardinality === "GUEST"):
        for (let guest of Object.keys(question.guestAnswers)) {
          if (question.guestAnswers[guest]?.unanswered) {
            return false;
          } else if (question.guestAnswers[guest]?.trim() === "") {
            return false;
          }
        }
        return true;
      case (question.type === "FREE_TEXT" && question.cardinality === "HOUSEHOLD"):
        return question.answer?.trim() !== "";
      case (question.type === "FREE_TEXT" && question.cardinality === "GUEST"):
        for (let guest of Object.keys(question.guestAnswers)) {
          if (question.guestAnswers[guest]?.trim() === "") {
            return false;
          }
        }
        return true;
      default:
        return;
    }
  }

  const isQuestionIncluded = (question) => {
    if (question.conditions.length === 0) return true;

    for (const condition of question.conditions) {
      const targetQuestion = formData.find((question) => question.id === condition.questionId);
      if (targetQuestion.guestAnswers) {
        if (condition.guests === 'ANY') {
          for (const guest of Object.keys(targetQuestion.guestAnswers)) {
            if (condition.comparator === '=') {
              if (targetQuestion.guestAnswers[guest] === condition.value) {
                return true;
              }
            }
          }
        }
      }

      return false;
    }
  }

  const filteredFormData = formData?.filter((question) => isQuestionIncluded(question));

  if (!household || !form || !filteredFormData) {
    return null;
  }

  if (isComplete) {
    return (
      <div className={styles.FormContainer}>
        <div className={styles.CompletedWarning}>
          You have already submitted your {form.name}. If you need to change your response please contact Mike or Meredith.
        </div>
        <button
          className={styles.FormControlButton}
          onClick={() => atcb_action(CALENDAR_EVENT)}>
          Add to Calendar
        </button>
      </div>
    );
  }

  return (
    <div className={styles.FormContainer}>
      <Carousel
        showIndicators={false}
        selectedItem={currentQuestion}
        showArrows={false}
        showStatus={false}
        infiniteLoop={false}
        showThumbs={false}
        useKeyboardArrows={false}
        autoPlay={false}
        stopOnHover={false}
        swipeable={false}
        width="100vw"
      >
        <div className={styles.CarouselQuestion}>
          <button
            className={styles.FormControlButton}
            onClick={() => setCurrentQuestion(currentQuestion + 1)}
          >
            Get Started
          </button>
        </div>
        {
          filteredFormData?.map((question, idx) => {
            let AnswerComponent;
            switch (true) {
              case (question.type === "YES_NO" && question.cardinality === "GUEST"):
                AnswerComponent = QuestionGuestYesNo;
                break;
              case (question.type === "YES_NO_QUALIFIED" && question.cardinality === "GUEST"):
                AnswerComponent = QuestionGuestYesNoQualified;
                break;
              case (question.type === "FREE_TEXT" && question.cardinality === "HOUSEHOLD"):
                AnswerComponent = QuestionHouseholdFreeText;
                break;
              case (question.type === "FREE_TEXT" && question.cardinality === "GUEST"):
                AnswerComponent = QuestionGuestFreeText;
                break;
              default:
                return;
            }

            return (
              <div ref={question.ref} className={styles.CarouselQuestion}>
                <div className={styles.Question}>
                  {question.question}
                  {question.required
                    ? <span className={styles.QuestionRequired}>(required)</span>
                    : null}
                </div>
                <div>
                  <AnswerComponent
                    question={question}
                    household={household}
                    guests={guests}
                    onChangeGuestAnswer={(guestId, answer) => setGuestAnswer(question.id, guestId, answer)}
                    onChangeHouseholdAnswer={(answer) => setHouseholdAnswer(question.id, answer)}
                  />
                </div>
                {filteredFormData[idx + 1]
                  ? <button
                    className={styles.FormControlButton}
                    onClick={() => setCurrentQuestion(currentQuestion + 1)}
                    disabled={!isQuestionValid(question)}
                  >
                    Next
                  </button>
                  : <button
                    className={styles.FormControlButton}
                    onClick={() => handleOnSubmit()}
                  >
                    Submit
                  </button>}
                {filteredFormData[idx - 1]
                  ? <button
                    className={styles.FormControlButtonSecondary}
                    onClick={() => setCurrentQuestion(currentQuestion - 1)}
                  >
                    Back
                  </button>
                  : null}
              </div>
            );
          })
        }
        <div className={styles.CarouselQuestion}>
          <h1>Thank you for your RSVP.</h1>
          <button
            className={styles.FormControlButton}
            onClick={() => atcb_action(CALENDAR_EVENT)}>
            Add to Calendar
          </button>
        </div>
      </Carousel>

    </div >
  )
}
