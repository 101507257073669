import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation } from "react-router-dom";
import { db } from "./firebase";
import { query, doc, getDoc, where } from "firebase/firestore";
import styles from './ContentPage.module.css';
import ContentPageForm from './ContentPageForm';


export default function ContentPage({ page, pages }) {
  let params = useParams();
  const location = useLocation();
  const [household, setHousehold] = useState();

  const fetchHousehold = async () => {
    if (!params.householdId) {
      return;
    }

    try {
      const householdDoc = await getDoc(doc(db, "weddings", "m-n-mwed", "households", params.householdId));
      const data = householdDoc.data();
      setHousehold(data);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching household data");
    }
  }

  useEffect(() => {
    fetchHousehold();
  }, [params.householdId, location]);

  return (
    <div key={page.docId} className={styles.Content}>
      {page.data.map((data) => {
        switch (data.type) {
          case "IMAGE":
            return (
              <div className={styles.ContentBlock}>
                <img className={styles.Image} src={data.url} />
              </div>
            )
          case "TEXT":
            return (
              <div
                className={styles.ContentBlock}
                dangerouslySetInnerHTML={{ __html: data.html }}
              />

            )
          case "SECTION_BREAK":
            return (
              <hr className={styles.SectionBreak} />
            )
          case "FORM":
            return (
              <ContentPageForm
                household={household}
                formId={data.formRef}
                isComplete={!!household?.formResponses?.find((response) => response.formId === data.formRef)}
              />
            )
          case "PAGE_LINK":
            const page = pages.find((page) => (page.docId === data.pageRef));
            if (page) {
              return (
                <div>
                  <Link className={styles.PageLink} to={`/${params.householdId}/${page.slug}`}>{data.title ? data.title : page.title}</Link>
                </div>
              )
            }
            return null;
          case "QUESTION":
            return (
              <div className={styles.Question}>
                <h4>{data.question}</h4>
                <div
                  className={styles.Answer}
                  dangerouslySetInnerHTML={{ __html: data.answer }}
                />
              </div>
            );
          default:
            return null;
        }
      })}
    </div>
  )
}
