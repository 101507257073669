// Config and function here taken from: https://blog.logrocket.com/user-authentication-firebase-react-apps/
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCLcFUlOXM_zzO3upXjaS5JSrANo1omcck",
  authDomain: "wedding-website-ed3da.firebaseapp.com",
  projectId: "wedding-website-ed3da",
  storageBucket: "wedding-website-ed3da.appspot.com",
  messagingSenderId: "933983615098",
  appId: "1:933983615098:web:ed6592075bd65534a323c3",
  measurementId: "G-84HXM4BY9Q"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export {
  db,
};
