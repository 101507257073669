import React from 'react';
import { useParams } from "react-router-dom";
import headerImage from "./header-image.webp"
import './Reset.css';
import styles from './Header.module.css';
import Nav from './Nav.js';

function Header({ pages }) {
  let params = useParams();

  return (
    <header className={styles.Header}>
      <div className={styles.HeaderImageContainer}>
        <img loading="eager" alt="" src={headerImage} className={styles.HeaderImage} />
      </div>
      <h1>Mike &amp; Meredith</h1>
      <Nav pages={pages} householdId={params.householdId} />
    </header>
  );
}

export default Header;
