import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import './Reset.css';
import styles from './Nav.module.css';

function Nav({ pages, householdId }) {
  const location = useLocation()
  const [slideMenuOpen, setSlideMenuOpen] = useState(false);

  useEffect(() => {
    setSlideMenuOpen(false);
  }, [location]);

  return (
    <>
      {pages
        ? <nav className={styles.Nav}>

          <div className={styles.MenuToggle}>
            <input type="checkbox" checked={slideMenuOpen} onChange={() => setSlideMenuOpen(!slideMenuOpen)} />
            <span></span>
            <span></span>
            <span></span>
            <div className={styles.SlideMenu}>
              <ul className={styles.SlideMenuNavLinks}>
                {pages.map((page) => {
                  return (
                    <li>
                      <Link to={`/${householdId}/${page.slug}`}>{page.title}</Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>



          <ul className={styles.Menu}>
            {pages.map((page) => {
              return (
                // Remove the trailing slash from the URL via the replace below.
                <li className={location.pathname.replace(/\/+$/, '') === `/${householdId}/${page.slug}`.replace(/\/+$/, '') ? styles.MenuItemActive : styles.MenuItem}>
                  <Link to={`/${householdId}/${page.slug}`}>{page.title}</Link>
                </li>
              );
            })}
          </ul>
        </nav>
        : null}
    </>
  );
}

export default Nav;
