import * as React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./Header";
import ContentPage from "./ContentPage";
import ScrollToTop from "./ScrollToTop";

const Page = ({ page }) => {
  return (<div>{page.title}</div>)
}

function getPageElement(pageRoute, pages) {
  switch (pageRoute.type) {
    case "CONTENT":
      return <ContentPage page={pageRoute.data} pages={pages} />
    default:
      return <Page page={pageRoute.data} pages={pages} />
  }
}

export default function Router({ pages }) {

  let pageRoutes = [];

  pages?.forEach((page) => {
    pageRoutes.push({
      path: `/:householdId/${page.slug}`,
      type: page.type,
      data: page,
      header: true,
    })
  });

  return (
    <>
      <ScrollToTop />
      {/* Include the header only for pages that need it */}
      <Routes>
        {pageRoutes?.filter((pageRoute) => pageRoute.header).map((pageRoute) => <Route key={pageRoute.path} exact path={pageRoute.path} element={<Header pages={pages} />} />)}
        {/* <Header pages={pages} /> */}
      </Routes>

      <main>
        <Routes>
          {pageRoutes?.map((pageRoute) => {
            return <Route key={pageRoute.path} exact path={pageRoute.path} element={getPageElement(pageRoute, pages)} />
          })}
        </Routes>
      </main>
    </>
  );
}
